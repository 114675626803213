<template lang="pug">
.main-wrapper.caixas-fechamento
	header.main-wrapper-header
		.p-grid.p-align-center
			.p-col-12.ta-right
				h1.text-header.text-secondary MedClub / <b>Histórico de Fechamento</b>

	TabView(v-if='supervisao' @tab-change='onTabChange')
		TabPanel(header='Supervisão' v-bind:ie_tipo_caixa="'S'" :active="filters.ie_tipo_caixa === 'S'" :disabled='waiting' @change='applyFilters()')
		TabPanel(header='Operacional' v-bind:ie_tipo_caixa="'O'" :active="filters.ie_tipo_caixa === 'O'" :disabled='waiting' @change='applyFilters()')

	Panel.mb-2(:value="list" header='Filtros' :toggleable='true' :collapsed='windowInnerWidth < 576' mode="indeterminate")
		.p-grid.p-fluid.p-align-end
			.p-col-12.p-md-4
				label.form-label Nome do Caixa:
				.p-inputgroup
					InputText(
						placeholder='Nome do Caixa'
						@keyup.enter.native='applyFilters()'
						@input='(value) => { if (!value) applyFilters() }'
						v-model='filters.nm_caixa'
					)
					Button(icon='jam jam-search' @click='applyFilters()')
			.p-col-12.p-md-4
				label.form-label Unidade:
				ProgressBar(v-if="options.unidades.length == 0" mode="indeterminate")
				.p-inputgroup(v-else)
					Dropdown(
						placeholder='Selecione Unidade...'
						:options='options.unidades'
						optionLabel='label'
						optionValue='value'
						@change='applyFilters()'
						@input='getSetor()'
						v-model='filters.cd_unidades_list'
						filter
					)
			.p-col-12.p-md-4
				label.form-label Setor:
				ProgressBar(v-if="loading.setor" mode="indeterminate")
				.p-inputgroup(v-else)
					Dropdown(
						placeholder='Selecione Setor...'
						:options='options.setores'
						optionLabel='label'
						optionValue='value'
						@change='applyFilters()'
						@input='() => { getSalas(true); this.filters.cd_salas_list = [] }'
						v-model='filters.cd_setores_list'
						:disabled="options.setores.length == 0"
						v-tooltip.bottom="options.setores.length == 0 ? 'Informe a unidade antes' : ''"
						filter
					)
			.p-col-12.p-md-4
				label.form-label Sala:
				ProgressBar(v-if="loading.sala" mode="indeterminate")
				.p-inputgroup(v-else)
					MultiSelect(
						placeholder='Selecione Sala...'
						:options='options.salas'
						optionLabel='label'
						optionValue='value'
						@change='applyFilters()'
						v-model='filters.cd_salas_list'
						:disabled="options.salas.length == 0"
						v-tooltip.bottom="options.salas.length == 0 ? 'Informe o setor antes' : ''"
						filter
					)
			.p-col-12.p-md-4(v-if="filters.ie_tipo_caixa === 'O' && supervisao")
				label.form-label Usuários:
				ProgressBar(v-if="options.usuariosOption.length == 0" mode="indeterminate")
				.p-inputgroup(v-else)
					MultiSelect(
						placeholder='Selecione Usuário...'
						:options='options.usuariosOption'
						optionLabel='label'
						optionValue='value'
						@change='applyFilters()'
						v-model='filters.cd_usuarios_list'
						filter
					)
			.p-col-12.p-md-2
				label.form-label Data:
				.p-inputgroup
					Calendar( v-model='filters.dt_saldo_i' dateFormat="dd/mm/yy" :locale="ptbr"
						:manualInput='false' :touchUI='windowInnerWidth < 576' @input='applyFilters()' )
					Button.p-button-danger(icon='jam jam-rubber' v-tooltip.top="'Limpar'" @click="() => {filters.dt_saldo_i = null; filters.dt_saldo = null; applyFilters();}")
			.p-col-12.p-md-2
				label.form-label Aberto:
				.p-inputgroup
					SelectButton(
						:options='options.aberto'
						optionLabel='label'
						optionValue='value'
						@input='applyFilters()'
						v-model='filters.ie_aberto'
					)
			.p-col-12.p-md-4
				Button(label='Limpar filtros' icon='jam jam-rubber' @click='limparFiltros()')

	ProgressBar(v-if='waiting' mode="indeterminate")
	div(v-else-if='list.length == 0')
		p.ta-center.text-secondary(style='margin-top: 40px;') Nenhum registro encontrado.

	div(v-else)
		DataView.dataview.my-2(:value="list" layout="grid")
			template(#grid="props")
				.p-col-12.p-md-3(style="padding: .5em" :class="{ disabled: !props.data.ie_status }")
					Panel.panel-list.ta-center(:header="props.data.nm_caixa" style='position: relative')
						.ta-left
							p <b>Nome do caixa:</b> {{ props.data.nm_caixa }}
							p(v-if="filters.ie_tipo_caixa == 'S'") <b>Saldo de fechamento:</b> {{ props.data.saldo_fechamento_f }}
							p(v-if="filters.ie_tipo_caixa == 'S'") <b>Previsão de fechamento:</b> {{ props.data.saldo_previsao_f }}
							p(v-if="filters.ie_tipo_caixa == 'S'") <b>Saldo atual:</b> {{ props.data.saldo_atual_f }}
							p(v-if="filters.ie_tipo_caixa == 'O'") <b>Sala:</b> {{ props.data.nm_unidade_medclub }}
							p(v-if="filters.ie_tipo_caixa == 'O' && supervisao") <b>Entradas (Somente Espécie):</b> {{ props.data.nr_entradas_f }}
							p(v-if="filters.ie_tipo_caixa == 'O' && supervisao") <b>Total sangrado:</b> {{ props.data.nr_sangrias_f }}
							p(v-if="supervisao") <b>Diferença do dia:</b> {{ props.data.saldo_diferenca_f }}
							p <b>Data do saldo:</b> {{ props.data.dt_saldo_f }}
							p <b>Aberto:</b> {{ props.data.aberto ? 'Sim' : 'Não' }}
						.ta-right.p-buttonset.mt-3
							Button.p-button-raised.p-button-rounded(icon="jam jam-eye"
								@click="selected = props.data; onSelect()")

		Paginator.dataview(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")

		Panel.datatable(header='Lista de fechamentos')
			DataTable(:value="list" :selection.sync="selected" selectionMode="single" dataKey="id" @row-select='onSelect')
				Column(headerStyle='width: 35%;' field='nm_caixa' header='Nome do caixa')
					template(#body='props')
						span {{ props.data.nm_caixa }} <br>
						em(v-if='props.data.nm_sala' style='font-size: 10px') Unidade: {{ props.data.nm_unidade }} <br> Setor: {{ props.data.nm_setor }} <br> Sala: {{ props.data.nm_sala }} <br>
				Column(headerStyle='width: 25%' field='saldo_fechamento_f' v-if="filters.ie_tipo_caixa == 'S'" header='Saldo de fechamento')
				Column(headerStyle='width: 25%' field='saldo_previsao_f' v-if="filters.ie_tipo_caixa == 'S'" header='Previsão de fechamento')
				Column(headerStyle='width: 25%' field='saldo_atual_f' v-if="filters.ie_tipo_caixa == 'S'" header='Saldo atual')
				Column(headerStyle='width: 20%' field='nm_usuario_abertura' v-if="filters.ie_tipo_caixa == 'O'" header='Usuário')
				Column(headerStyle='width: 25%' field='nr_entradas_f' v-if="filters.ie_tipo_caixa == 'O'" header='Entradas (Somente Espécie)')
				Column(headerStyle='width: 25%' field='nr_sangrias_f' v-if="filters.ie_tipo_caixa == 'O' && supervisao" header='Total sangrado')
				Column(headerStyle='width: 25%' field='saldo_diferenca_f' v-if="supervisao" header='Diferença do dia')
				Column(headerStyle='width: 20%;' bodyStyle='text-align: center;' field='dt_saldo_f' header='Data do saldo')
				Column(header='Aberto' headerStyle='width: 15%;' bodyStyle='text-align: center; padding: 0')
					template(#body='props')
						.cell(:class="{ disabled: ! props.data.ie_aberto  }") {{ props.data.ie_aberto ? 'Sim' : 'Não' }}

			Paginator(:rows='paginator.per_page' :first='(paginator.page - 1) * paginator.per_page' :totalRecords='paginator.count' @page="onPage($event)")
</template>

<style lang="scss">
	.caixas-fechamento {
		.dataview {
			@media all and (min-width: 577px) {
				display: none;
			}
		}
		.p-tabview-panels {
			padding: 0 !important;
			border: none !important;
		}
		.p-tabview {
			padding-bottom: 0;
		}
		.datatable {
			@media all and (max-width: 576px) {
				display: none;
			}
			.cell {
				padding: 10px 0;
				text-align: center;
				background-color: #e4f8e1;
				&.disabled  { background-color: #ffd8d2; }
			}
		}
		.dialogApagar {
			.p-progress-spinner {
				width: 29px;
				height: auto;
			}
		}
	}
	.p-tooltip {
		max-width: none;
	}
</style>

<script>
	import ProgressBar from 'primevue/progressbar'
	import DataView from 'primevue/dataview'
	import Panel from 'primevue/panel'
	import Paginator from 'primevue/paginator'
	import DataTable from 'primevue/datatable'
	import Column from 'primevue/column'
	import InputText from 'primevue/inputtext'
	import InputMask from 'primevue/inputmask'
	import Button from 'primevue/button'
	import Dialog from 'primevue/dialog'
	import ProgressSpinner from 'primevue/progressspinner'
	import Dropdown from 'primevue/dropdown'
	import SelectButton from 'primevue/selectbutton'
	import TabView from 'primevue/tabview'
	import TabPanel from 'primevue/tabpanel'
	import Calendar from 'primevue/calendar'
	import MultiSelect from 'primevue/multiselect'
	import Tooltip from 'primevue/tooltip'

	import { Caixas, Usuario, UnidadesMedclub } from './../../middleware'
	import moment from 'moment'
	import wsConfigs from './../../middleware/configs'
	import { pCalendarLocale_ptbr } from './../../utils'


	export default {
		components: { ProgressBar, DataView, Panel, Paginator, DataTable, InputMask, TabView, TabPanel, Calendar,
			Column, Button, Dialog, ProgressSpinner, InputText, Dropdown, SelectButton, MultiSelect, Tooltip, },
		directives: { tooltip: Tooltip },
		beforeRouteEnter (to, from, next) {
			next(vm => {
				vm.verificarRota()
				let routeName = vm.$route.path.split('/')[1]
				if (from.path.split('/')[1] === routeName && vm.$store.state.filters)
					vm.filters = {...vm.filters, ...vm.$store.state.filters}
				else vm.setTab()
				vm.$store.commit('clearFilters')
				if (vm.filters.cd_salas_list.length != 0) vm.getSalas()
				vm.applyFilters()
			})
		},
		beforeRouteLeave (to, from, next) {
			let routeName = this.$route.path.split('/')[1]
			if (to.path.split('/')[1] === routeName)
				if (this.filters) this.$store.commit('setFilters', this.filters)
			next()
		},
		created () { 
			this.getUsuarios()
			this.getUnidades()
		},
		data () {
			return {
				list: [],
				windowInnerWidth: window.innerWidth,
				ptbr: pCalendarLocale_ptbr,
				waiting: false,
				filters: {
					ie_tipo_caixa: null,
					cd_unidades_list: null,
					cd_setores_list: null,
					cd_salas_list: [],
					nm_caixa: '',
					ie_aberto: null,
					dt_saldo: null,
					dt_saldo_i: null,
					cd_usuarios_list: null,
				},
				options:{
					usuariosOption: [],
					unidades: [],
					setores: [],
                    salas: [],
					aberto: [{label: 'Sim', value: 'true'}, {label: 'Não', value: 'false'}]
                },
				paginator: {
                    page: this.$route.query.pg ? this.$route.query.pg : 1,
					per_page: wsConfigs.paginator_perPage,
					count: 0
				},
				loading: {
					setor: false,
					sala: false,
				},
				selected: null,
				supervisao: false,
			}
		},
		methods: {
			resetFilters() {
				this.filters.cd_unidades_list = null
				this.filters.cd_setores_list = null
				this.filters.cd_salas_list = []
				this.filters.nm_caixa = ''
				this.filters.ie_aberto = null
				this.filters.dt_saldo = null
				this.filters.dt_saldo_i = null
				this.filters.cd_usuarios_list = []
			},
			limparFiltros() {
				this.resetFilters()
				this.applyFilters()
			},
			verificarRota() {
				let rotaAtual = this.$route.path.split('/')[1]
				this.supervisao = rotaAtual === "fechamento-caixa"
			},
			setTab() {
				if	(this.supervisao) this.filters.ie_tipo_caixa = 'S'
				else this.filters.ie_tipo_caixa = 'O'
			},
			formatPrice (val) {
				return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(val)
			},
			getList (params) {
				this.waiting = true
				if(this.filters.ie_tipo_caixa=='S') {
					return Caixas.findAllFechamentoSupervisao(params).then(response => {
						if (response.status == 200) {
							this.paginator.count = response.data.count
							response.data.results.forEach(caixa => {
								if (caixa.dt_saldo)
									caixa.dt_saldo_f = moment(caixa.dt_saldo).format('DD/MM/YYYY - HH:mm:ss')
									caixa.saldo_atual_f = this.formatPrice(caixa.saldo_atual)
									caixa.saldo_diferenca_f = this.formatPrice(caixa.saldo_diferenca)
									caixa.saldo_fechamento_f = this.formatPrice(caixa.saldo_fechamento)
									caixa.saldo_previsao_f = this.formatPrice(caixa.saldo_previsao)
							})
							this.list = response.data.results
						}
						this.waiting = false
						return true
					})
				}
				if(this.filters.ie_tipo_caixa=='O') {
					return Caixas.findAllFechamentoOperacional(params).then(response => {
						if (response.status == 200) {
							this.paginator.count = response.data.count
							response.data.results.forEach(caixa => {
								if (caixa.dt_saldo)
									caixa.dt_saldo_f = moment(caixa.dt_saldo).format('DD/MM/YYYY - HH:mm:ss')
									caixa.nr_entradas_f = this.formatPrice(caixa.nr_entradas)
									caixa.nr_sangrias_f = this.formatPrice(caixa.nr_sangrias)
									caixa.saldo_diferenca_f = this.formatPrice(caixa.saldo_diferenca)
							})
							this.list = response.data.results
						}
						this.waiting = false
						return true
					})
				}
			},
			getUnidades (){
                UnidadesMedclub.findAll().then(response=>{
                    if (response.status == 200) {
						this.options.unidades.push({label: "- Selecione -", value: null})
                        response.data.forEach(e => {
                            this.options.unidades.push({label: e.nm_unidade, value: e.id})
                        })
                    }
                })
            },
			getSetor (){
				this.options.setores = []
				this.options.salas = []
				this.filters.cd_setores_list = null
				this.filters.cd_salas_list = []
				if (this.filters.cd_unidades_list) {
					this.loading.setor = true
					UnidadesMedclub.findAllSetor({cd_unidade: this.filters.cd_unidades_list}).then(response=>{
						if (response.status == 200) {
							this.options.setores.push({label: "- Selecione -", value: null})
							if (response.data.length == 0) this.$toast.info("Nenhum setor encontrado.", { duration: 3000 });
							response.data.forEach(e => {
								this.options.setores.push({label: e.nm_setor, value: e.id})
							})
						}
					})
					this.loading.setor = false
				}
            },
			getSalas (setor = false){
				this.options.salas = []
				if(setor && !this.filters.cd_setores_list) return
				this.loading.sala = true
				const params = {}
				if(setor)
					params ["cd_setor"] = this.filters.cd_setores_list
                UnidadesMedclub.findAllSala(params).then(response=>{
					if (response.data.length == 0) this.$toast.info("Nenhuma sala encontrada.", { duration: 3000 });
                    if (response.status == 200) {
                        response.data.forEach(e => {
                            this.options.salas.push({label: e.nm_sala, value: e.id})
                        })
                    }
                })
				this.loading.sala = false
            },
			getUsuarios (){
                Usuario.findUserBySafe({ie_caixa: true}).then(response=>{
                    if (response.status == 200) {
                        response.data.forEach(e => {
                            this.options.usuariosOption.push({label: e.username, value: e.id})
                        })
                    }
                })
            },
			checkEmptyField (field) {
				if (! this.filters[field].length) this.applyFilters()
			},
			applyFilters () {
				let params = { paginacao: true, page: this.paginator.page, per_page: this.paginator.per_page, ie_usuario: !this.supervisao }
				if (this.$route.query.pg != this.paginator.page) this.$router.replace( { query: { pg: this.paginator.page } } )
				if(this.filters.dt_saldo_i) this.filters.dt_saldo = moment(this.filters.dt_saldo_i, 'DD/MM/YYYY').utcOffset('-03:00').format('YYYY-MM-DD')
				Object.keys(this.filters).forEach((key) => { if (this.filters[key]) params[key] = this.filters[key] })
				this.getList(params)
			},
			onPage (ev) {
				this.paginator.page = ev.page + 1
				this.applyFilters()
			},
			onSelect (ev) { // eslint-disable-line
				if(this.filters.ie_tipo_caixa==="S") this.$router.push(`/fechamento-caixa/visualizar-supervisao/${ ev.data.id }/`)
				else if(this.supervisao) this.$router.push(`/fechamento-caixa/visualizar/${ ev.data.id }/`)
				else this.$router.push(`/historico-operacional/visualizar/${ ev.data.id }/`)
			},
			onTabChange (ev) {
				this.resetFilters()
				this.filters.ie_tipo_caixa = ev.tab.$attrs.ie_tipo_caixa
				this.paginator.page = 1
				this.applyFilters()
			},
		}
	}
</script>
